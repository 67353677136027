var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{attrs:{"outlined":false}},[_c('div',{staticClass:"top-elevation"},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-img',{attrs:{"src":require('@/assets/images/bckg.jpg'),"height":_vm.banner_height,"width":"100%"},on:{"load":function($event){return _vm.onResize()}}},[_c('div',{ref:"for_height_banner_div"},[_c('v-container',{staticClass:"my-7"},[_c('div',{staticClass:"d-inline-flex flex-wrap"},[_c('v-sheet',{staticClass:"\n                pt-5\n                pb-3\n                pl-7\n                pr-15\n                mr-4\n                width-max-content\n                border-lightgray\n                d-inline-block\n              ",attrs:{"color":"rgba(245,245,245,0.95)"}},[_c('h2',{staticClass:"text-md-h3 font-weight-light pb-1 text-h4"},[_vm._v(" "+_vm._s(_vm.$t("global.banner.title_line_1"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("global.banner.title_line_2"))+" ")]),_c('div',{staticClass:"mb-8 subheading",staticStyle:{"max-width":"825px"}},[_c('span',{staticClass:"accent--text-dark"},[_vm._v(_vm._s(_vm.$t("global.strataggem_capital")))]),_vm._v(" "+_vm._s(_vm.$t("global.banner.slogan"))+" ")]),(_vm.full_version)?_c('div',{staticClass:"mb-2 items-2columns body-1"},[_c('ul',{staticClass:"zero-padding"},_vm._l((_vm.$t('global.banner.list_1')),function(item,i){return _c('li',{key:'l1' + i},[_vm._v(" "+_vm._s(_vm.$t("global.banner.list_1." + i))+" ")])}),0),_c('ul',{staticClass:"zero-padding"},_vm._l((_vm.$t('global.banner.list_2')),function(item,i){return _c('li',{key:'l2' + i},[_vm._v(" "+_vm._s(_vm.$t("global.banner.list_2." + i))+" ")])}),0)]):_vm._e()]),_c('v-sheet',{staticClass:"\n                pa-3\n                pt-4\n                width-max-content\n                min-content-height\n                text-center\n                border-lightgray\n              ",class:{
                'd-inline-block': _vm.$vuetify.breakpoint.xlOnly,
                'mt-4 pl-5 d-inline-flex align-center flex-row':
                  _vm.$vuetify.breakpoint.lgAndDown,
              },attrs:{"color":"rgba(245,245,245,0.95)"}},[_c('div',{staticClass:"pb-1 pr-4"},[_c('h4',{staticClass:"font-weight-light text-h5"},[_vm._v(" "+_vm._s(_vm.$t("global.banner.tax_credit_approval"))+" "),_c('br'),(_vm.show_full_banner_info)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("global.banner.tax_credit_name"))+" ")]):_vm._e()])]),_c('div',[_c('v-img',{staticClass:"d-inline-block mr-1 ml-1",attrs:{"max-width":_vm.logo_cir_width,"alt":"Crédit d'impôt recherche (agrément)","src":require('@/assets/images/cir.svg')}}),_c('v-img',{staticClass:"d-inline-block mr-1 ml-1",attrs:{"max-width":_vm.logo_cir_width,"alt":"Crédit d'impôt innovation (agrément)","src":require('@/assets/images/cii.svg')}})],1)])],1)])],1)]):_c('div',{staticClass:"pt-2",attrs:{"height":_vm.banner_height,"width":"100%"}},[_c('div',{ref:"for_height_banner_div"},[_c('v-container',{staticClass:"my-3 my-sm-4 my-lg-7 pa-0"},[_c('div',[_c('v-sheet',{staticClass:"px-0 width-max-content",class:{
                'pt-5 pb-3': _vm.show_full_banner_info,
                'margin-large': _vm.$vuetify.breakpoint.xsOnly,
              },attrs:{"outlined":false,"color":"transparent"}},[_c('h2',{staticClass:"text-md-h3",class:{
                  'mx-5 font-weight-regular text-h5': _vm.$vuetify.breakpoint.xsOnly,
                  'mx-5 font-weight-light text-h4': _vm.$vuetify.breakpoint.smAndUp,
                }},[_vm._v(" "+_vm._s(_vm.$t("global.banner.title_line_1"))+" "),(_vm.$vuetify.breakpoint.smAndUp)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("global.banner.title_line_2"))+" ")]),_c('v-img',{staticClass:"image-right mt-5 mb-6",attrs:{"src":require('@/assets/images/banner_image.jpg'),"width":"100%","height":"7em"},on:{"load":function($event){return _vm.onResize()}}}),_c('div',{staticClass:"px-3 mx-5 pb-5"},[_c('div',{class:{
                    subheading: _vm.$vuetify.breakpoint.smAndUp,
                    'mb-8': _vm.show_full_banner_info,
                    'mt-1': _vm.$vuetify.breakpoint.xsOnly,
                  },staticStyle:{"max-width":"825px"}},[_c('span',{staticClass:"accent--text-dark"},[_vm._v(_vm._s(_vm.$t("global.strataggem_capital")))]),_vm._v(" "+_vm._s(_vm.$t("global.banner.slogan"))+" ")]),(_vm.full_version)?_c('div',{staticClass:"mt-5 mb-2 items-2columns body-1"},[_c('ul',{staticClass:"zero-padding",class:{
                      'li-zero-margin-and-padding':
                        _vm.$vuetify.breakpoint.xsOnly,
                    }},_vm._l((_vm.$t('global.banner.list_1')),function(item,i){return _c('li',{key:'l1' + i},[_vm._v(" "+_vm._s(_vm.$t("global.banner.list_1." + i))+" ")])}),0),_c('ul',{staticClass:"zero-padding",class:{
                      'li-zero-margin-and-padding':
                        _vm.$vuetify.breakpoint.xsOnly,
                    }},_vm._l((_vm.$t('global.banner.list_2')),function(item,i){return _c('li',{key:'l2' + i},[_vm._v(" "+_vm._s(_vm.$t("global.banner.list_2." + i))+" ")])}),0)]):_vm._e()])],1),(!_vm.is_home_page || _vm.$vuetify.breakpoint.smAndUp)?_c('v-sheet',{staticClass:"\n                pb-3\n                pl-3\n                ml-5\n                width-max-content\n                min-content-height\n                text-center\n                d-inline-flex align-center flex-row\n              ",class:{
                'flex-column': _vm.$vuetify.breakpoint.width < 370,
                'mb-2': _vm.$vuetify.breakpoint.xsOnly,
                'mb-3': _vm.$vuetify.breakpoint.smOnly,
                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
              },attrs:{"color":"transparent"}},[_c('div',{staticClass:"pb-1",class:{
                  'pr-4': _vm.$vuetify.breakpoint.width >= 370,
                }},[_c('h4',{staticClass:"font-weight-light",class:{
                    'text-h6':
                      _vm.$vuetify.breakpoint.mdOnly ||
                      _vm.$vuetify.breakpoint.smOnly,
                    'text-h8': _vm.$vuetify.breakpoint.xsOnly,
                  }},[_vm._v(" "+_vm._s(_vm.$t("global.banner.tax_credit_approval"))+" "),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("global.banner.tax_credit_name"))+" ")])])]),_c('div',[_c('v-img',{staticClass:"d-inline-block mr-1 ml-1",attrs:{"max-width":_vm.logo_cir_width,"alt":"Crédit d'impôt recherche (agrément)","src":require('@/assets/images/cir.svg')},on:{"load":function($event){return _vm.onResize()}}}),_c('v-img',{staticClass:"d-inline-block mr-1 ml-1",attrs:{"max-width":_vm.logo_cir_width,"alt":"Crédit d'impôt innovation (agrément)","src":require('@/assets/images/cii.svg')},on:{"load":function($event){return _vm.onResize()}}})],1)]):_vm._e()],1)])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }