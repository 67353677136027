<template>
  <v-main id="home">
    <language-changer />

    <banner />

    <router-view id="content" />
  </v-main>
</template>

<script>
import Banner from "../app_specific/Banner.vue";
import LanguageChanger from "../app_specific/LanguageChanger.vue";

const PAGES_EXCLUDE_FROM_SCROLL = ["docs", "company", "expertise", "products"];

export default {
  components: { Banner, LanguageChanger },
  name: "CoreView",

  watch: {
    async $route() {
      if (
        "" != this.$route.hash &&
        !PAGES_EXCLUDE_FROM_SCROLL.includes(this.$route.name)
      ) {
        await this.$vuetify.goTo(0, { duration: 0 });
        this.$vuetify.goTo(this.$route.hash, { resetScroll: true });
      }
    },
  },
};
</script>
