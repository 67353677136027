<template>
  <base-card :outlined="false">
    <div class="top-elevation">
      <!-- Banner desktop version (lgAndUp size) -->
      <v-img
        v-if="$vuetify.breakpoint.lgAndUp"
        :src="require('@/assets/images/bckg.jpg')"
        @load="onResize()"
        :height="banner_height"
        width="100%"
      >
        <div ref="for_height_banner_div">
          <v-container class="my-7">
            <div class="d-inline-flex flex-wrap">
              <v-sheet
                color="rgba(245,245,245,0.95)"
                class="
                  pt-5
                  pb-3
                  pl-7
                  pr-15
                  mr-4
                  width-max-content
                  border-lightgray
                  d-inline-block
                "
              >
                <h2 class="text-md-h3 font-weight-light pb-1 text-h4">
                  {{ $t("global.banner.title_line_1") }}
                  <br />
                  {{ $t("global.banner.title_line_2") }}
                </h2>

                <div class="mb-8 subheading" style="max-width: 825px">
                  <span class="accent--text-dark">{{
                    $t("global.strataggem_capital")
                  }}</span>
                  {{ $t("global.banner.slogan") }}
                </div>

                <div v-if="full_version" class="mb-2 items-2columns body-1">
                  <ul class="zero-padding">
                    <li
                      v-for="(item, i) in $t('global.banner.list_1')"
                      :key="'l1' + i"
                    >
                      {{ $t("global.banner.list_1." + i) }}
                    </li>
                  </ul>

                  <ul class="zero-padding">
                    <li
                      v-for="(item, i) in $t('global.banner.list_2')"
                      :key="'l2' + i"
                    >
                      {{ $t("global.banner.list_2." + i) }}
                    </li>
                  </ul>
                </div>
              </v-sheet>

              <!-- Logo of CIR and CII-->
              <v-sheet
                color="rgba(245,245,245,0.95)"
                class="
                  pa-3
                  pt-4
                  width-max-content
                  min-content-height
                  text-center
                  border-lightgray
                "
                :class="{
                  'd-inline-block': $vuetify.breakpoint.xlOnly,
                  'mt-4 pl-5 d-inline-flex align-center flex-row':
                    $vuetify.breakpoint.lgAndDown,
                }"
              >
                <div class="pb-1 pr-4">
                  <h4 class="font-weight-light text-h5">
                    {{ $t("global.banner.tax_credit_approval") }}
                    <br />
                    <span class="font-weight-bold" v-if="show_full_banner_info">
                      {{ $t("global.banner.tax_credit_name") }}
                    </span>
                  </h4>
                </div>
                <div>
                  <v-img
                    class="d-inline-block mr-1 ml-1"
                    :max-width="logo_cir_width"
                    alt="Crédit d'impôt recherche (agrément)"
                    :src="require('@/assets/images/cir.svg')"
                  />
                  <v-img
                    class="d-inline-block mr-1 ml-1"
                    :max-width="logo_cir_width"
                    alt="Crédit d'impôt innovation (agrément)"
                    :src="require('@/assets/images/cii.svg')"
                  />
                </div>
              </v-sheet>
            </div>
          </v-container>
        </div>
      </v-img>

      <!-- Banner mobile and tablets version (mdAndDown) -->
      <div v-else class="pt-2" :height="banner_height" width="100%">
        <div ref="for_height_banner_div">
          <v-container class="my-3 my-sm-4 my-lg-7 pa-0">
            <div>
              <v-sheet
                :outlined="false"
                color="transparent"
                class="px-0 width-max-content"
                :class="{
                  'pt-5 pb-3': show_full_banner_info,
                  'margin-large': $vuetify.breakpoint.xsOnly,
                }"
              >
                <h2
                  class="text-md-h3"
                  :class="{
                    'mx-5 font-weight-regular text-h5': $vuetify.breakpoint.xsOnly,
                    'mx-5 font-weight-light text-h4': $vuetify.breakpoint.smAndUp,
                  }"
                >
                  {{ $t("global.banner.title_line_1") }}
                  <br v-if="$vuetify.breakpoint.smAndUp" />
                  {{ $t("global.banner.title_line_2") }}
                </h2>

                <v-img
                  class="image-right mt-5 mb-6"
                  :src="require('@/assets/images/banner_image.jpg')"
                  @load="onResize()"
                  width="100%"
                  height="7em"
                />

                <div class="px-3 mx-5 pb-5">
                  <div
                    :class="{
                      subheading: $vuetify.breakpoint.smAndUp,
                      'mb-8': show_full_banner_info,
                      'mt-1': $vuetify.breakpoint.xsOnly,
                    }"
                    style="max-width: 825px"
                  >
                    <span class="accent--text-dark">{{
                      $t("global.strataggem_capital")
                    }}</span>
                    {{ $t("global.banner.slogan") }}
                  </div>

                  <div
                    v-if="full_version"
                    class="mt-5 mb-2 items-2columns body-1"
                  >
                    <ul
                      class="zero-padding"
                      :class="{
                        'li-zero-margin-and-padding':
                          $vuetify.breakpoint.xsOnly,
                      }"
                    >
                      <li
                        v-for="(item, i) in $t('global.banner.list_1')"
                        :key="'l1' + i"
                      >
                        {{ $t("global.banner.list_1." + i) }}
                      </li>
                    </ul>

                    <ul
                      class="zero-padding"
                      :class="{
                        'li-zero-margin-and-padding':
                          $vuetify.breakpoint.xsOnly,
                      }"
                    >
                      <li
                        v-for="(item, i) in $t('global.banner.list_2')"
                        :key="'l2' + i"
                      >
                        {{ $t("global.banner.list_2." + i) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </v-sheet>

              <!-- Logo of CIR and CII-->
              <v-sheet
                v-if="!is_home_page || $vuetify.breakpoint.smAndUp"
                color="transparent"
                class="
                  pb-3
                  pl-3
                  ml-5
                  width-max-content
                  min-content-height
                  text-center
                  d-inline-flex align-center flex-row
                "
                :class="{
                  'flex-column': $vuetify.breakpoint.width < 370,
                  'mb-2': $vuetify.breakpoint.xsOnly,
                  'mb-3': $vuetify.breakpoint.smOnly,
                  'mb-5': $vuetify.breakpoint.mdAndUp,
                }"
              >
                <div
                  class="pb-1"
                  :class="{
                    'pr-4': $vuetify.breakpoint.width >= 370,
                  }"
                >
                  <h4
                    class="font-weight-light"
                    :class="{
                      'text-h6':
                        $vuetify.breakpoint.mdOnly ||
                        $vuetify.breakpoint.smOnly,
                      'text-h8': $vuetify.breakpoint.xsOnly,
                    }"
                  >
                    {{ $t("global.banner.tax_credit_approval") }}
                    <br />
                    <span class="font-weight-bold">
                      {{ $t("global.banner.tax_credit_name") }}
                    </span>
                  </h4>
                </div>
                <div>
                  <v-img
                    class="d-inline-block mr-1 ml-1"
                    :max-width="logo_cir_width"
                    alt="Crédit d'impôt recherche (agrément)"
                    :src="require('@/assets/images/cir.svg')"
                    @load="onResize()"
                  />
                  <v-img
                    class="d-inline-block mr-1 ml-1"
                    :max-width="logo_cir_width"
                    alt="Crédit d'impôt innovation (agrément)"
                    :src="require('@/assets/images/cii.svg')"
                    @load="onResize()"
                  />
                </div>
              </v-sheet>
            </div>
          </v-container>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script>
export default {
  data: function () {
    return {
      full_version: false,
      banner_height: 300,
    };
  },

  watch: {
    $route() {
      this.setFullBannerIfHomePage();
      this.onResize();
    },
  },

  created() {
    this.setFullBannerIfHomePage();
    window.addEventListener("resize", this.onResize);
    window.addEventListener("load", this.onResize);
  },

  mounted() {
    // Additionally, trigger onResize when the component is mounted
    this.onResize();
  },

  updated() {
    // Additionally, trigger onResize when the component is updated
    this.onResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    show_full_banner_info() {
      if (!this.full_version) return false;

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        default:
          return true;
      }
    },

    logo_cir_width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 60;
        case "sm":
          return 70;
        default:
          return 80;
      }
    },

    is_home_page() {
      return "home" == this.$route.name;
    },
  },

  methods: {
    setFullBannerIfHomePage() {
      if (this.is_home_page) {
        this.full_version = true;
      } else {
        this.full_version = false;
      }
    },

    onResize() {
      // Set banner image height
      if (null != this.$refs && this.$refs["for_height_banner_div"]) {
        let banner_inner_div = this.$refs["for_height_banner_div"];
        var margin_bottom = 18;

        if (this.$vuetify.breakpoint.lgAndUp) {
          margin_bottom += 10;
        }

        if (this.$vuetify.breakpoint.smAndUp) {
          margin_bottom += 28;
        }
        // else {
        //   // xs only
        //   // no need for a 2nd margin if a CIR logo is hidden
        //   if (!this.is_home_page) margin_bottom += 0;
        // }

        // mt-7 = 28 pixels
        this.banner_height = banner_inner_div.clientHeight + margin_bottom;
      }
    },
  },
};
</script>

<style scoped>
.v-application ul.listing {
  padding-left: 0px;
  margin-top: 1em;
}

.v-application ul li {
  padding-right: 3em;
  margin-left: 1em;
}

.subheading {
  font-size: large;
}
.items-2columns {
  display: flex;
  flex-wrap: wrap;
}
.width-max-content {
  width: max-content;
  max-width: 100%;
}
li {
  color: rgba(0, 0, 0, 0.87);
}
.top-elevation {
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.2);
  background: #ebecf05e;
}
</style>
